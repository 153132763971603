// Удобно использовать в сочетании с компонентом ModalInfo.vue
export default {
  data() {
    return {
      isOpenModalInfo: false,
      titleModal: '',
      textModal: '',
      textModalPreWrap: '',
      modalIconName: '',
      redirectAfterCloseModal: null,
    };
  },
  methods: {
    closeModalInfo(type = 'isOpenModalInfo') {
      this.importModal = false;
      this[type] = false;
      this.titleModal = '';
      this.textModalPreWrap = '';
      this.textModal = '';
    },
  },
  watch: {
    isOpenModalInfo(value) {
      if (value === false) {
        if (this.redirectAfterCloseModal) {
          this.$router.push(this.redirectAfterCloseModal);
          this.redirectAfterCloseModal = null;
        }
      }
    },
  },
};
