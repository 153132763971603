export const DEFAULT_FILTER_VALUES = {
  countryIds: [], // Страна
  countryGroupIds: null, // Группа стран
  tradeItemTypeId: null, // Вид предмета торговли
  industryIds: [], // Отрасль для услуги
  tnvedIndustryIds: [], // Отрасль для товара
  aggregateIndustryIds: [], // Агрегетированные данные, которые распределяются на industryIds и tnvedIndustryIds
  vedName: null, // наименование товара/услуги
  vedCode: null, // код товара/услуги
  barrierCategoryIds: [], // категория барьера
  measureKindIds: [], // вид барьера
  defenceMeasureIds: [], // Мера защиты рынка
  isBarrierActive: null, // boolean барьер активен?
  isBarrierLiberalized: null, // boolean барьер либерализован
  responsibleOrgIds: [], // Орган, ответственный за введение меры
  description: null, // Описание барьера
  preventedDamageAmountFrom: null, // Сумма предотвращенного ущерба, млн $/год (от)
  preventedDamageAmountTo: null, // Сумма предотвращенного ущерба, млн $/год (до)
  allegedDamageAmountFrom: null, // Сумма предполагаемого ущерба, млн $/год (от)
  allegedDamageAmountTo: null, // Сумма предполагаемого ущерба, млн $/год (до)
  nomenclatureName: null,
  codes: null, // Наименование товарной номенклатуры
  lastLiberalizationYearFrom: null, // год последней либерализации (от)
  lastLiberalizationYearTo: null, // год последней либерализации (до)
  revisionStartDateFrom: null, // Дата пересмотра меры (От)
  revisionStartDateTo: null, // Дата пересмотра меры (до)
  beginDateFrom: null, // дата начала ограничения (от)
  beginDateTo: null, // дата начала ограничения (до)
  endDateFrom: null, // дата окончания ограничения (от)
  endDateTo: null, // дата окончания ограничения (от)
  normativeDocument: null, // Нормативный документ, вводящий меру
  feeAmount: null, /// Размер пошлины
  responsibleEmployeeIds: [], // Ответственный от Минэкономразвития России
  interestedLegalPersons: null, // Заинтересованные юридические лица
  note: null, // Примечание
  actualizedFrom: null,
  actualizedTo: null,
  isActive: null, // Запись активна
  createdFrom: null, // Дата создания (от)
  createdTo: null, // Дата создания (до)
  modifiedFrom: null, // Дата изменения (от)
  modifiedTo: null, // Дата изменения
  statusId: null,
  liberalizationDateFrom: null, // дата либерализации (от)
  liberalizationDateTo: null, // дата либерализации (до)
  barrierIdentificationFrom: null, // дата выявления (от)
  barrierIdentificationTo: null, // дата выявления (до)
  barrierRemovalFrom: null, // Дата устранения барьера (от)
  barrierRemovalTo: null, // Дата устранения барьера (до)
  projectListId: null, // Перечень проекта
};

// Иные поля, которые связаны с ограничениями
// isPublished	boolean  опубликовать как новость
// tnvedIds	[...] Товар (ТН ВЭД)
// okvedIds	[...] Услуга (ОКВЭД)
// allCountries	boolean Все страны
// tnvedAggregation	string Совокупность кодов ТНВЭД (примечание)

export const DEFAULT_BARRIER_CATEGORY_LIST = [
  {
    text: 'Мера',
    id: '1',
    tradeItemTypeId: 1,
  },
  {
    text: 'Угроза',
    id: '2',
    tradeItemTypeId: 1,
  },
  {
    text: 'Запрет',
    id: '3',
    tradeItemTypeId: 2,
  },
  {
    text: 'Ограничение',
    id: '4',
    tradeItemTypeId: 2,
  },
];

export const MODAL_INFO_MESSAGES = {
  EMPTY_DATA:
    'Не удалось сформировать отчет, так как не были заданы параметры. Пожалуйста, укажите необходимые параметры и повторите попытку.',
  START_DOWNLOAD: 'Началось скачивание отчета!',
  NO_DATA:
    'По заданным параметрам не найдено ни одной записи реестра. Пожалуйста, попробуйте изменить параметры и повторите попытку.',
  IMPORT_SUCCESS: 'Список добавлен.',
  IMPORT_ERROR: 'Ошибка при импорте данных.',
  REMOVE_SUCCESS: 'Запись удалена!',
  REMOVE_ERROR: 'Запись не удалена.',
  CHECK_FIELDS: 'Проверьте правильность заполнения полей.',
  DATE_LOVER_LIMIT_ERROR: 'Дата окончания не может быть меньше даты введения.',
  SAVE_SUCCESS: 'Форма успешно сохранена.',
  SAVE_ERROR: 'При сохранение произошла ошибка.',
  COORDINATION_SUCCESS: 'Форма отправлена на согласование.',
  COORDINATION_ACCEPT: 'Форма согласована.',
  COORDINATION_ERROR: 'Во время отправки на согласование произошла ошибка.',
  REQUEST_ERROR: 'Во время отправки формы произошла ошибка.',
  REQUEST_SUCCESS: 'Данные успешно сохранены.',
};
