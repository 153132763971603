<template>
  <div class="modal__body-wrapper">
    <div class="mt-2">
      <div class="form-control-input-wrapper-redesigned">
        <input v-model="request.term" placeholder="Поиск" class="form-control" @:input="search" />
      </div>
      <template v-if="page.items">
        <div class="table-container customTable mb-16">
          <table class="stockTable">
            <thead>
              <tr>
                <th class="th__checkbox"></th>
                <th class="th__code">Код</th>
                <th class="th__name">Наименование</th>
              </tr>
            </thead>
            <tbody v-if="page.items.length">
              <tr
                v-for="item in page.items"
                :key="item.id"
                @click="handleMobileClick(item)"
                class="tr__mobile"
                :class="{
                  tr__mobile_active: getIsActiveMobileTr(item),
                }"
              >
                <td class="td__checkbox">
                  <template v-if="!returnCodes">
                    <div class="checkboxInput">
                      <input
                        type="checkbox"
                        v-model="selection"
                        :disabled="isCheckDisabled(item)"
                        :value="item.id"
                        :id="`${item.name}${item.id}`"
                        @click="checkClick"
                      />
                      <label :for="`${item.name}${item.id}`" class="form-label" />
                    </div>
                  </template>
                  <template v-else>
                    <div class="checkboxInput">
                      <input
                        type="checkbox"
                        v-model="selection"
                        :disabled="isCheckDisabled(item)"
                        :value="item.code"
                        :id="item.code"
                        @click="checkClick"
                      />
                      <label :for="item.code" class="form-label" />
                    </div>
                  </template>
                </td>
                <td class="td__code">{{ item.code }}</td>
                <td class="td__name" :class="getTdTextClasses(item)" :style="{ paddingLeft: item.level * 10 + 'px' }">
                  <span
                    class="td__code_mobile"
                    :class="{
                      'td__code_mobile-folder': !item.isLeaf,
                      textwhite: selection.some((x) => x === item.id),
                    }"
                    >{{ item.code }}</span
                  >
                  <div
                    :class="{
                      ...getTdTextClasses(item),
                      textwhite: selection.some((x) => x === item.id),
                    }"
                  >
                    <i v-if="!item.isLeaf" class="fas fa-folder"></i>
                    {{ item.name }}
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-if="!page.items.length">
              <tr>
                <td class="text-center" colspan="4">Нет данных.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal__body-pagination-redesigned" v-if="page.pagesTotal > 1">
          <PaginationRedesigned
            :page-count="page.pagesTotal"
            :current-page="page.pageNumber"
            :isShowMoreVisible="false"
            @change="onPageChange($event, false)"
            @more="onPageChange($event, true)"
          />
        </div>
        <div class="modal__body-footer-redesigned">
          <ButtonStock class="mr-8 btn--average restriction-form__button_save" @:click="select">Выбрать</ButtonStock>
          <ButtonStock btn-type="blueColorNoBorder" title="Отмена" v-on:click="close" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import PaginationRedesigned from '@/common/components/redesigned/PaginationRedesigned';
  import Constants from '@/common/constants';
  import resizeEventListener from '@/common/mixins/resizeEventListener';

  import ButtonStock from '@/components/buttons/ButtonStock';

  import API from '../api/nsi';

  export default {
    name: 'CatalogSelectorRedesigned',
    components: { ButtonStock, PaginationRedesigned },
    mixins: [resizeEventListener],
    props: {
      route: {
        type: String,
        required: true,
      },
      multiple: {
        type: Boolean,
        default: true,
      },
      selectGroupsOnly: {
        type: Boolean,
        default: false,
      },
      selectLeafsOnly: {
        type: Boolean,
        default: false,
      },
      returnCodes: {
        type: Boolean,
        default: false,
      },
      selectedIds: Object,
      codes: {
        type: Array,
        default() {
          return [];
        },
      },
    },
    data() {
      return {
        page: {},
        request: {
          pageNumber: 1,
          pageSize: 8,
          term: '',
        },
        selection: this.selectedIds ?? [],
        timerId: null,
        screenWidth: window.innerWidth,
      };
    },
    watch: {
      route: function () {
        this.selection = [];
        this.loadPage();
      },
      selectedIds: function () {
        this.selection = this.selectedIds;
      },
    },
    created() {
      this.loadPage();
    },
    mounted() {
      let wrapper = document.querySelector('.form-control-input-wrapper-redesigned');
      wrapper.addEventListener(
        'select',
        function (event) {
          event.stopImmediatePropagation();
        },
        true,
      );
    },
    methods: {
      // вызывается в миксине resizeEventListener
      /**@public */
      onResize() {
        this.screenWidth = window.innerWidth;
      },
      loadPage(append) {
        API.search(this.route, this.request).then((response) => {
          if (!append) {
            this.page = {};
            this.$nextTick(() => {
              this.page = response.data;
            });
          } else if (response.data) {
            this.page.pageNumber = response.data.pageNumber;
            this.page.items = this.page.items.concat(response.data.items);
          }
        });
      },
      search() {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        this.timerId = setTimeout(() => {
          this.request.pageNumber = 1;
          this.loadPage();
        }, 1000);
      },
      onPageChange(pageNumber, addToExist) {
        this.request.pageNumber = pageNumber;
        this.loadPage(addToExist);
      },
      select() {
        if (!this.selection?.length) {
          Constants.alert.fire('Выбор', 'Вы не выбрали ни одного элемента.', 'error');
          return;
        }
        this.$emit('select', this.selection);
      },
      checkClick(e) {
        if (!this.multiple) {
          const id = this.selection.find((x) => x !== e.target.value);
          if (id) {
            this.selection.splice(this.selection.indexOf(id), 1);
            this.selection.push(parseInt(e.target.value));
          }
          //this.selection = [e.target.value];
        }
      },
      isCheckDisabled(item) {
        return (
          (this.selectGroupsOnly && item.isLeaf) ||
          (this.selectLeafsOnly && !item.isLeaf) ||
          this.isConstraintCode(item)
        );
      },
      isConstraintCode(item) {
        return this.codes.length > 0 && !this.codes.includes(item.code.slice(0, 2));
      },
      close() {
        this.$emit('close');
      },
      handleMobileClick(item) {
        if (this.screenWidth > 576 || this.isCheckDisabled(item)) return;
        const hasItem = this.selection.some((select) => select === item.id);
        if (hasItem) {
          this.selection.splice(this.selection.indexOf(item.id), 1);
        } else {
          if (!this.multiple) {
            this.selection = [item.id];
          } else {
            this.selection.push(item.id);
          }
        }
      },
      getIsActiveMobileTr(item) {
        return this.selection.some((x) => x === item.id);
      },
      getTdTextClasses(item) {
        return {
          textupper: item.level === 1,
          textbold: !item.isLeaf,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .textupper {
    text-transform: uppercase;
  }

  .textbold {
    font-weight: bold;
  }

  .table-container {
    box-shadow: none;
  }

  .table-container.customTable table.stockTable thead th {
    &:nth-of-type(1) {
      width: 50px;
    }
    &:nth-of-type(2) {
      width: 120px;
    }
  }
  .checkboxInput label:before {
    top: -7px;
  }

  .td__code_mobile {
    display: none;
  }

  @media (max-width: $mobile) {
    .th__checkbox,
    .th__code,
    .td__checkbox,
    .td__code {
      display: none;
    }

    .td__code_mobile {
      display: block;
      font: $fira-12;
      color: $dark-grey-1;

      &-folder {
        padding-left: 16px;
      }
    }
    .tr__mobile {
      &_active {
        background: $approval-text;
      }
    }
    .textwhite {
      color: #fff;
    }
  }
</style>
