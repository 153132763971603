<template>
  <div class="container-page container-page-rktv">
    <h2 class="title-inner title--h1">
      Запись реестра ограничительных мер, применяемых третьими странами в отношении российских товаров, услуг и
      инвестиций
    </h2>

    <div class="restriction-form__container">
      <div class="restriction-form__block">
        <h3 class="restriction-form__title">Информация о товаре/услуге</h3>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label required">Страна</p>
          <SelectMultiComponentCheckbox
            v-model="card.countryIds"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="countryList"
            required
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Группа стран</p>
          <SelectSingleComponentCheckbox
            v-model="card.countryGroupId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="countryGroupList"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label required">Вид предмета торговли</p>
          <SelectSingleComponentCheckbox
            v-model="card.tradeItemTypeId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="tradeItemTypeList"
            required
            :canClear="false"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">{{ labelName }}</p>
          <ComponentInput v-model="card.name" mod="rectangular" placeholder="Введите" />
        </div>
        <div
          v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.tnved)"
          class="form-group required disabled-select"
        >
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label required">Товар (ТН ВЭД)</p>
            <ComponentInput
              :select2Settings="select2SettingsMultiple"
              :select2Options="tnved"
              v-model="card.tnvedIds"
              required
              multiple
              mod="rectangular"
              type="select2"
            />
            <i
              class="fas fa-search catalog-icon"
              title="Найти в каталоге"
              @click="openCatalog('nsitnved', 'Выбор товара')"
            ></i>
          </div>
        </div>
        <div
          class="form-group disabled-select required"
          v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.okved)"
        >
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label required">Услуга (ОКВЭД)</p>
            <ComponentInput
              :select2Settings="select2SettingsMultiple"
              :select2Options="okved"
              v-model="card.okvedIds"
              mod="rectangular"
              required
              multiple
              type="select2"
            />
            <i
              class="fas fa-search catalog-icon"
              title="Найти в каталоге"
              @click="openCatalog('nsiokved', 'Выбор услуги')"
            ></i>
          </div>
        </div>

        <div v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.tnved)">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label required">Отрасль</p>
            <SelectSingleComponentCheckbox
              v-model="card.tnvedIndustryId"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="tnvedIndustryList"
              required
              :canClear="false"
            />
          </div>
        </div>

        <div v-else-if="Number(card.tradeItemTypeId) === Number(tradeItemType.okved)">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label required">Отрасль</p>
            <SelectSingleComponentCheckbox
              v-model="card.industryId"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="okvedIndustryList"
              required
              :canClear="false"
            />
          </div>
        </div>

        <div v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.tnved)">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label">Наименование товарной номенклатуры</p>
            <ComponentInput v-model="card.nomenclatureName" mod="rectangular" placeholder="Введите" />
          </div>
        </div>

        <div v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.tnved)">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label">Совокупность кодов по товару</p>
            <ComponentInput v-model="card.codes" mod="rectangular" placeholder="Введите" />
          </div>
        </div>

        <div v-if="Number(card.tradeItemTypeId) === Number(tradeItemType.tnved)">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label">Совокупность кодов ТНВЭД (примечание)</p>
            <ComponentInput v-model="card.tnvedAggregation" type="textarea" mod="rectangular" placeholder="Введите" />
          </div>
        </div>
      </div>

      <div class="restriction-form__block">
        <h3 class="restriction-form__title">Информация о барьере</h3>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label required">Категория барьера</p>
          <SelectSingleComponentCheckbox
            v-model="card.barrierCategoryId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getBarrierCategoryList"
            required
            :canClear="false"
          />
        </div>

        <div v-if="card.barrierCategoryId == 1" class="form-group">
          <div class="restriction-form__inputBlock">
            <p class="restriction-form__label">Мера защиты рынка</p>
            <SelectSingleComponentCheckbox
              v-model="card.defenceMeasureId"
              placeholder="Выберите"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              :options="defenceMeasureList"
            />
          </div>
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label required">Вид барьера</p>
          <SelectSingleComponentCheckbox
            v-model="card.measureKindId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="getMeasureKindList"
            required
            :canClear="false"
          />
        </div>

        <div class="restriction-form__barrier-date-container">
          <div class="restriction-form__checkbox-container">
            <div class="checkboxInput">
              <input type="checkbox" v-model="card.isBarrierActive" id="chkIsActive" />
              <label for="chkIsActive" class="form-label">Барьер активен</label>
            </div>

            <div class="checkboxInput">
              <input type="checkbox" v-model="card.isBarrierLiberalized" id="chkIsLiberalized" />
              <label for="chkIsLiberalized" class="form-label">Барьер либерализован</label>
            </div>
          </div>

          <div class="restriction-form__checkbox-container">
            <div class="restriction-form__inputBlock">
              <p class="restriction-form__label">Дата выявления</p>
              <DateComponent
                v-model="card.barrierIdentification"
                modClass="redesigned"
                placeholderForDate=""
                placeholder=""
                :clearable="false"
                dateIcon
              />
            </div>
            <div class="restriction-form__inputBlock">
              <p class="restriction-form__label">Дата либерализации</p>
              <DateComponent
                v-model="card.liberalizationDate"
                modClass="redesigned"
                placeholderForDate=""
                placeholder=""
                :clearable="false"
                dateIcon
              />
            </div>
          </div>
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Год последней либерализации</p>
          <ComponentInput v-model="card.lastLiberalizationYear" type="year" mod="rectangular" placeholder="Введите" />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label required">Описание барьера</p>
          <ComponentInput
            v-model="card.description"
            type="textarea"
            mod="rectangular"
            placeholder="Введите"
            required
            :textareaStyles="{ 'min-height': '376px' }"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Сумма предотвращенного ущерба, млн $/год</p>
          <ComponentInput
            v-model="card.preventedDamageAmount"
            type="number"
            mod="rectangular"
            placeholder="Введите"
            :min="0"
            :step="0.1"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Сумма предполагаемого ущерба, млн $/год</p>
          <ComponentInput
            v-model="card.allegedDamageAmount"
            type="number"
            :min="0"
            :step="0.1"
            mod="rectangular"
            placeholder="Введите"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Дата устранения барьера</p>
          <DateComponent
            v-model="card.barrierRemoval"
            modClass="redesigned"
            placeholderForDate=""
            placeholder=""
            :clearable="false"
            dateIcon
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Орган, ответственный за введение меры</p>
          <SelectSingleComponentCheckbox
            v-model="card.responsibleOrgId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="organList"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Ответственный от Минэкономразвития России</p>
          <SelectSingleComponentCheckbox
            v-model="card.responsibleEmployeeId"
            placeholder="Выберите"
            trackBy="text"
            valueProp="id"
            labelOptions="text"
            :options="employeeList"
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Дата введения</p>
          <DateComponent
            v-model="card.beginDate"
            modClass="redesigned"
            placeholderForDate=""
            placeholder=""
            :clearable="false"
            dateIcon
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Дата окончания</p>
          <DateComponent
            v-model="card.endDate"
            modClass="redesigned"
            placeholderForDate=""
            placeholder=""
            :clearable="false"
            dateIcon
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Дата пересмотра меры</p>
          <DateComponent
            v-model="card.revisionStartDate"
            modClass="redesigned"
            placeholderForDate=""
            placeholder=""
            :clearable="false"
            dateIcon
          />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Нормативный документ, вводящий меру</p>
          <ComponentInput v-model="card.normativeDocument" mod="rectangular" placeholder="Введите" />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Размер пошлины</p>
          <ComponentInput v-model="card.feeAmount" mod="rectangular" placeholder="Введите" />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Заинтересованные юридические лица</p>
          <ComponentInput v-model="card.interestedLegalPersons" mod="rectangular" placeholder="Введите" />
        </div>

        <div class="restriction-form__inputBlock">
          <p class="restriction-form__label">Примечание</p>
          <ComponentInput
            v-model="card.note"
            type="textarea"
            mod="rectangular"
            placeholder="Введите"
            :textareaStyles="{ 'min-height': '376px' }"
          />
        </div>
      </div>

      <div class="restriction-form__block">
        <h3 class="restriction-form__title">Документы</h3>

        <FileMultiUploadRedesigned :entity="card" prop-name="files" @uploaded="fileUploaded" @deleteFile="removeFile" />
      </div>
      <div class="restriction-form__block_checkbox">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chkActive" />
          <label for="chkActive" class="form-label">Запись активна</label>
        </div>
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isPublished" id="chkPublish" />
          <label for="chkPublish" class="form-label">Опубликовать как новость</label>
        </div>
      </div>

      <div class="mb-16">
        <div class="restriction-form__button-container">
          <div class="restriction-form__button-group">
            <ButtonStock class="btn--average restriction-form__button_save" @:click="save">Сохранить</ButtonStock>

            <ButtonStock
              class="restriction-form__button_cancel"
              btn-type="blueColorNoBorder"
              title="Отменить"
              @:click="close"
            />
          </div>
          <div class="restriction-form__button-group">
            <ButtonStock
              v-if="inWork"
              class="restriction-form__button_approve"
              btn-type="checkedBlue"
              title="Отправить на согласование"
              @:click="sendApprove"
            />
            <ButtonStock
              v-if="canApprove && onApproval"
              class="restriction-form__button_approve"
              btn-type="approval"
              title="Согласовать"
              @:click="approveMeasure"
            />
            <ButtonStock
              v-if="canApprove && onApproval"
              class="restriction-form__button_reject"
              btn-type="rejected"
              title="Отклонить"
              @:click="refuseMeasure"
            />
          </div>
        </div>
        <ModalComponent title="Отклонить" headType="redesigned" @close="dialogCancel = false" v-model="dialogCancel">
          <div class="form__body">
            <div class="form__body-inputBlock fira-sans">
              <p>Причина</p>
              <textarea placeholder="Напишите причину отклонения" v-model="reason"></textarea>
              <div class="invalid-msg" v-if="v$.reason.$error">Поле обязательно для заполнения</div>
            </div>
            <div class="form__body-buttons">
              <ButtonStock class="btn--average" @:click="refuseReportSend">Подтвердить</ButtonStock>
              <ButtonStock @click="dialogCancel = false" btnType="blueColorNoBorder" title="Закрыть" />
            </div>
          </div>
        </ModalComponent>
      </div>
    </div>
  </div>

  <section>
    <ModalComponent
      v-model="catalog.open"
      content-class="modal--new"
      headType="redesigned"
      :width="950"
      :title="catalog.title"
      @close="closeCatalog"
    >
      <CatalogSelectorRedesigned
        :selected-ids="catalog.selectedIds"
        :route="catalog.route"
        :multiple="true"
        modClass="redesigned"
        @select="updateCatalogField"
        @close="closeCatalog"
      />
    </ModalComponent>
  </section>

  <ModalInfo
    v-model="isOpenModalInfo"
    :titleModal="titleModal"
    :textModal="textModal"
    :modalIconName="modalIconName"
    @closeModal="closeModalInfo"
  />
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { required, requiredIf } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import FileMultiUploadRedesigned from '@/common/components/redesigned/FileMultiUploadRedesigned.vue';
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';
  import security from '@/common/mixins/security';

  import ButtonStock from '@/components/buttons/ButtonStock';
  import ModalComponent from '@/components/modals/ModalComponent';

  import CatalogSelectorRedesigned from '@/modules/nsi/components/CatalogSelectorRedesigned';

  import OrgApi from '../../admin/api/organization';
  import UserApi from '../../admin/api/users';
  import CatalogApi from '../../nsi/api/catalog';
  import CountryApi from '../../nsi/api/country';
  import CountryGroupApi from '../../nsi/api/countryGroup';
  import NsiApi from '../../nsi/api/nsi';
  import Api from '../api/restrictmeasures';
  import { DEFAULT_BARRIER_CATEGORY_LIST, MODAL_INFO_MESSAGES } from '../utils/consts';

  export default {
    name: 'RestrictionMeasureEditPage',
    components: {
      ModalComponent,
      CatalogSelectorRedesigned,
      FileMultiUploadRedesigned,
      DateComponent,
      ComponentInput,
      ButtonStock,
      SelectMultiComponentCheckbox,
      SelectSingleComponentCheckbox,
      ModalInfo,
    },

    mixins: [security, modalInfo],

    data() {
      return {
        id: this.$route.params.id,
        card: {
          name: '',
          responsibleEmployeeId: 0,
          files: [],
          tradeItemTypeId: null,
        },
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
          includePicture: false,
        },
        tradeItemType: Constants.tradeItemType,
        reason: '',
        dialogCancel: false,
        catalog: {
          route: '',
          title: '',
          selectedIds: [],
          open: false,
        },
        currentUser: {
          id: null,
          name: '',
        },
        canApprove: false,
        isLoading: false,
        countriesLoaded: false,
        countryGroupsLoaded: false,
        cardLoaded: false,
        // данные для опций селектов
        countryList: [],
        countryGroupList: [],
        tradeItemTypeList: [],
        tnved: [{ text: 'Все товары', id: 0 }],
        okvedIndustryList: [],
        tnvedIndustryList: [],
        okved: [{ text: 'Все услуги', id: 0 }],
        barrierCategoryList: DEFAULT_BARRIER_CATEGORY_LIST,
        measureKindList: [],
        defenceMeasureList: [],
        organList: [],
        employeeList: [],
      };
    },

    created() {
      this.checkPermissions([
        Constants.Permissions.RestrictionMeasureAccess,
        Constants.Permissions.RestrictionMeasureEdit,
      ]);
      this.checkPermission(Constants.Permissions.RestrictionMeasureApprove).then((response) => {
        this.canApprove = response.data;
      });
      this.loadUser();
      this.loadDictionaries();
    },

    setup: () => ({ v$: useVuelidate() }),

    computed: {
      inWork() {
        return this.card.id && this.card.statusId == 1;
      },
      onApproval() {
        return this.card.id && this.card.statusId == 2;
      },
      getMeasureKindList() {
        return this.measureKindList.filter((x) => x.barrierCategoryId == this.card.barrierCategoryId);
      },
      labelName() {
        if (Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.tnved)) return 'Название товара';
        if (Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.okved)) return 'Название услуги';

        return 'Название предмета торговли';
      },
      getBarrierCategoryList() {
        return this.card.tradeItemTypeId === null
          ? this.barrierCategoryList
          : this.barrierCategoryList.filter((item) => item.tradeItemTypeId == this.card.tradeItemTypeId);
      },
    },

    methods: {
      loadUser() {
        var profile = this.$store.state.auth?.oidc?.profile;
        if (profile == null) profile = JSON.parse(localStorage.getItem('userProfileData'));

        this.currentUser.id = profile?.employee?.id;
        this.currentUser.name = profile.employee?.fullName;
      },
      loadCard() {
        this.isLoading = true;
        if (this.id > 0) {
          Api.find(this.id).then((response) => {
            this.card = response.data;
            if (this.card.allCountries) this.card.countryIds = ['0'];
            if (this.card.allTradeItems) {
              if (this.card.tradeItemTypeId == 1) this.card.tnvedIds = ['0'];
              else this.card.okvedIds = ['0'];
            }

            this.$nextTick(() => {
              this.isLoading = false;
            });
          });
        } else {
          this.card.responsibleEmployeeId = this.currentUser.id;
        }
      },
      loadDictionaries() {
        NsiApi.search('nsitradeitemtype', this.defaultRequest)
          .then((response) => {
            this.tradeItemTypeList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsiindustry', this.defaultRequest)
          .then((response) => {
            this.okvedIndustryList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsitnvedindustry', this.defaultRequest)
          .then((response) => {
            this.tnvedIndustryList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsimeasurekind', this.defaultRequest)
          .then((response) => {
            this.measureKindList = response.data.items.map((x) => {
              return {
                text: x.name,
                id: x.id,
                barrierCategoryId: x.barrierCategoryId,
              };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        NsiApi.search('nsimarketdefencemeasure', this.defaultRequest)
          .then((response) => {
            this.defenceMeasureList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        CountryApi.search(this.defaultRequest)
          .then((response) => {
            this.countryList = [{ text: 'Все страны', id: 0 }].concat(
              response.data.items.map((x) => {
                return {
                  text: x.name,
                  id: x.id,
                  countryGroupId: x.countryGroupId,
                };
              }),
            );
            this.countriesLoaded = true;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        let request = Object.assign({ typeIds: [Constants.organizationType.foiv] }, this.defaultRequest);
        OrgApi.searchShortFormat(request)
          .then((response) => {
            this.organList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        request = Object.assign({ organizationIds: [Constants.organizationMinekId] }, this.defaultRequest);
        UserApi.search(request)
          .then((response) => {
            this.employeeList = response.data.items.map((x) => {
              return { text: x.fullName, id: x.id };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        CountryGroupApi.search(this.defaultRequest)
          .then((response) => {
            this.countryGroupList = response.data.items.map((x) => {
              return { text: x.name, id: x.id };
            });
            this.countryGroupsLoaded = true;
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      save() {
        this.v$.$touch();

        if (this.v$.card.$invalid) {
          this.titleModal = 'Ошибка';
          this.textModal = MODAL_INFO_MESSAGES.CHECK_FIELDS;
          this.modalIconName = 'errorIcon';
          this.isOpenModalInfo = true;
          return;
        }

        if (this.card.endDate < this.card.beginDate) {
          this.titleModal = 'Ошибка';
          this.textModal = MODAL_INFO_MESSAGES.DATE_LOVER_LIMIT_ERROR;
          this.modalIconName = 'errorIcon';
          this.isOpenModalInfo = true;
          return;
        }

        Api.update(this.card)
          .then((res) => {
            this.titleModal = 'Сохранение';
            this.textModal = MODAL_INFO_MESSAGES.SAVE_SUCCESS;
            this.modalIconName = 'acceptIcon';
            this.isOpenModalInfo = true;
            if (this.card.id) {
              this.loadCard();
            } else {
              this.redirectAfterCloseModal = {
                name: 'RestrictionMeasureEditPage',
                params: { id: res.data.id },
              };
            }
          })
          .catch(() => {
            this.titleModal = 'Сохранение';
            this.textModal = MODAL_INFO_MESSAGES.SAVE_ERROR;
            this.modalIconName = 'errorIcon';
            this.isOpenModalInfo = true;
          });
      },
      openCatalog(route, title) {
        this.catalog.route = route;
        this.catalog.title = title;
        const selectedIds = route === 'nsitnved' ? this.card.tnvedIds : this.card.okvedIds;
        this.catalog.selectedIds = selectedIds;
        this.catalog.open = true;
      },
      updateCatalogField(selection) {
        this.closeCatalog();
        if (this.catalog.route === 'nsitnved') {
          this.card.tnvedIds = selection;
        } else {
          this.card.okvedIds = selection;
        }
      },
      closeCatalog() {
        this.catalog.open = false;
      },
      fileUploaded(e) {
        this.card.files.push(e.data);
      },
      removeFile(e) {
        const file = this.card.files.find((x) => x.id === e.id);
        const index = this.card.files.indexOf(file);
        if (index > -1) {
          this.card.files.splice(index, 1);
        }
      },
      close() {
        this.$router.push({ name: 'RestrictionMeasuresPage' });
      },
      sendApprove() {
        Api.sendApproval(this.card.id)
          .then(() => {
            this.titleModal = 'Отправка на согласование';
            this.textModal = MODAL_INFO_MESSAGES.COORDINATION_SUCCESS;
            this.modalIconName = 'acceptIcon';
            this.isOpenModalInfo = true;
            this.redirectAfterCloseModal = '/foreignmarkets/restrictmeasures';
          })
          .catch(() => {
            this.titleModal = 'Отправка на согласование';
            this.textModal = MODAL_INFO_MESSAGES.COORDINATION_ERROR;
            this.modalIconName = 'errorIcon';
            this.isOpenModalInfo = true;
          });
      },
      approveMeasure() {
        Api.approveMeasure(this.card)
          .then(() => {
            this.titleModal = 'Согласование';
            this.textModal = MODAL_INFO_MESSAGES.COORDINATION_ACCEPT;
            this.modalIconName = 'acceptIcon';
            this.isOpenModalInfo = true;
            this.redirectAfterCloseModal = '/foreignmarkets/restrictmeasures';
            this.loadCard();
          })
          .catch(() => {
            this.titleModal = 'Согласование';
            this.textModal = MODAL_INFO_MESSAGES.REQUEST_ERROR;
            this.modalIconName = 'errorIcon';
            this.isOpenModalInfo = true;
          });
      },
      refuseReportSend() {
        this.v$.$touch();
        if (this.v$.reason.$invalid) {
          this.titleModal = 'Ошибка';
          this.textModal = MODAL_INFO_MESSAGES.CHECK_FIELDS;
          this.modalIconName = 'errorIcon';
          this.isOpenModalInfo = true;
          return;
        }

        var data = {
          restrictionMeasureId: this.card.id,
          reason: this.reason,
        };

        Api.refuseMeasure(data)
          .then(() => {
            this.titleModal = 'Отклонение';
            this.textModal = MODAL_INFO_MESSAGES.REQUEST_SUCCESS;
            this.modalIconName = 'acceptIcon';
            this.isOpenModalInfo = true;
            this.redirectAfterCloseModal = '/foreignmarkets/restrictmeasures';

            this.dialogCancel = false;
            this.loadCard();
          })
          .catch(() => {
            this.titleModal = 'Отклонение';
            this.textModal = MODAL_INFO_MESSAGES.REQUEST_ERROR;
            this.modalIconName = 'errorIcon';
            this.isOpenModalInfo = true;
          });
      },
      refuseMeasure() {
        this.dialogCancel = true;
      },
    },

    validations() {
      return {
        card: {
          countryIds: { required },
          tradeItemTypeId: { required },
          tnvedIndustryId: {
            required: requiredIf(Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.tnved)),
          },
          industryId: {
            required: requiredIf(Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.okved)),
          },
          tnvedIds: {
            required: requiredIf(Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.tnved)),
          },
          okvedIds: {
            required: requiredIf(Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.okved)),
          },
          barrierCategoryId: { required },
          description: { required },
        },

        reason: { required },
      };
    },

    watch: {
      countriesLoaded: {
        handler: function (value) {
          if (value && this.countryGroupsLoaded && !this.cardLoaded) {
            this.cardLoaded = true;
            this.loadCard();
          }
        },
      },
      countryGroupsLoaded: {
        handler: function (value) {
          if (value && this.countriesLoaded && !this.cardLoaded) {
            this.cardLoaded = true;
            this.loadCard();
          }
        },
      },
      'card.tnvedIds': {
        handler: function (value) {
          if (!Object.values(value).length) return;
          CatalogApi.getListByIds('nsitnved', value).then((response) => {
            const list = response.data.map((x) => ({ id: x.id, text: x.name }));
            list.forEach((item) => {
              if (!this.tnved.find((x) => x.id === item.id)) {
                this.tnved.push(item);
              }
            });
          });

          if (this.card.tnvedIds.length > 1 && this.card.tnvedIds.includes('0')) this.card.tnvedIds = ['0'];

          if (Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.tnved))
            this.card.allTradeItems = this.card.tnvedIds.includes('0');
        },
        deep: true,
      },
      'card.okvedIds': {
        handler: function (value) {
          if (!value) return;
          CatalogApi.getListByIds('nsiokved', value).then((response) => {
            const list = response.data.map((x) => ({ id: x.id, text: x.name }));
            list.forEach((item) => {
              if (!this.okved.find((x) => x.id === item.id)) {
                this.okved.push(item);
              }
            });
          });

          if (this.card.okvedIds.length > 1 && this.card.okvedIds.includes('0')) this.card.okvedIds = ['0'];

          if (Number(this.card.tradeItemTypeId) === Number(this.tradeItemType.okved))
            this.card.allTradeItems = this.card.okvedIds.includes('0');
        },
        deep: true,
      },
      'card.countryIds': {
        handler: function (value) {
          if (!value) return;
          if (this.card.countryIds.length > 1 && this.card.countryIds.includes('0')) this.card.countryIds = ['0'];

          this.card.allCountries = this.card.countryIds.includes('0');

          if (!this.isLoading && this.countriesLoaded && this.countryGroupsLoaded) this.card.countryGroupId = null;
        },
      },
      'card.countryGroupId': {
        handler: function (value) {
          if (!value) return;
          this.isLoading = true;
          this.card.countryIds = this.countryList.filter((x) => x.countryGroupId == value).map((x) => x.id);
          this.$nextTick(() => {
            this.isLoading = false;
          });
        },
      },
      'card.barrierCategoryId': {
        handler: function (value) {
          if (!value) return;
          if (!this.isLoading) {
            this.card.measureKindId = null;
          }
        },
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../styles/restrictionMeasureEditPage.scss';
  @import '@/assets/theme/default/title.scss';
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
</style>

<style lang="scss" scoped>
  .form-container {
    .row-custom {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @media (max-width: $laptop) {
    .form-container {
      .row-custom {
        .col-6 {
          width: 100%;
          max-width: initial;
          flex: none;
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }
</style>
